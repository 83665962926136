<template>
  <div>
    <v-container>
      <v-row>
        <v-col xl="10" class="mx-auto my-5 my-lg-16 ">
          <login-form />
        </v-col>
      </v-row>
    </v-container>
    <a class="open_seller d-lg-none" href="/register-shop">
      <img src="@/assets/img/seller_login.png" class="menu-icon me-4" />
      {{ $t('open_online_store_as_a_seller') }}
    </a>
  </div>
</template>

<script>
import LoginForm from '../../components/auth/LoginForm.vue'
export default {
    components: { LoginForm },
}
</script>
<style scoped>
.open_seller {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 62px;
  height: 45px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  color: red;
  font-size: 14px;
  z-index: 11;
}
.open_seller > img {
  width: 20px;
  margin-left: 10px;
}
</style>
